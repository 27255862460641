import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit, OnDestroy {
  isDarkBg: boolean = false;
  private routerSubscription?: Subscription;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.isDarkBg = (this.router.url.includes('/profile') || this.router.url.includes('/volunteer') || this.router.url.includes('/jobs') || this.router.url.includes('/membership'));
    // Subscribe to the router events to detect changes
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current URL matches the profile page
        this.isDarkBg = (this.router.url.includes('/profile') || this.router.url.includes('/volunteer') || this.router.url.includes('/jobs') || this.router.url.includes('/membership'));
      }
    });
  }
  ngOnDestroy(): void {
    // Unsubscribe from the router events to avoid memory leaks
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
