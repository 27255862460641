import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { BaseComponent } from '@app/shared/base-component';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent extends BaseComponent implements OnInit, OnDestroy {
  isDarkBg: boolean = false;
  isAuthenticatedUser: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private routerSubscription?: Subscription;
  private readonly ActivatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly authService: AuthService = inject(AuthService);

  constructor(private router: Router) {
    super();
  }
  // isProfilePage
  ngOnInit(): void {
    this.getUserStatus();
    this.isDarkBg = (this.router.url.includes('/profile') || this.router.url.includes('/volunteer') || this.router.url.includes('/jobs') || this.router.url.includes('/membership'));
    // Subscribe to the router events to detect changes
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current URL matches the profile page
        this.isDarkBg = (this.router.url.includes('/profile') || this.router.url.includes('/volunteer') || this.router.url.includes('/jobs') || this.router.url.includes('/membership'));
      }
    });
  }
  override ngOnDestroy(): void {
    super.ngOnDestroy();
    // Unsubscribe from the router events to avoid memory leaks
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private getUserStatus(): void {
    this.ActivatedRoute.data.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res && res['auth']) {
        this.isAuthenticatedUser.next(
          res['auth']['isAuthenticatedUser'] || false,
        );
        this.authService.isAuthenticatedSubject.next(
          res['auth']['isAuthenticatedUser'] || false,
        );
        console.log("maincomponet:" , res['auth']);
      }
    });
  }

  protected override translatedContent(): void {
  }
}
